import React from 'react'
import { SimpleGrid, Text, } from '@chakra-ui/react'
import { Configure } from 'react-instantsearch-hooks-web'
import Transition from 'components/layout/Transition'
import { Subheader } from 'components/layout'
import { AlgoliaSearch, InfiniteScroll, SearchBox, useStats } from '../gatsby-plugin-algolia'
import { ProductListItem } from '../components/product'

// @TODO: If/when InstantSearch wrapper is moved to a layout component, we no longer need this wrapper so the useStats hook is a child of InstantSearch
const SubheaderWrapper = () => {
  const {query, nbHits} = useStats()
  return <Subheader
    leftComponent={
      <Text py={'1.5rem'} textStyle='rimd'>{`SEARCH → ${query.length > 0 ? query : 'All'} (${nbHits})`}</Text>
    }
  />
}

const SearchPage = () => {
  return (
    <Transition>
      <AlgoliaSearch
        routing={true}
        // initialUiState={{
        //   arguments: {
        //     configure: {
        //       hitsPerPage: 4,
        //     },
        //   }
        // }}
      >
        <Configure hitsPerPage={4}/>
        <SubheaderWrapper/>
        <SearchBox/>
        <SimpleGrid spacing={'5rem'} columns={[1, null, null, 2, null, 3, 4]} px={'5rem'} py={'5rem'}>
          <InfiniteScroll hitComponent={ProductListItem}/>
        </SimpleGrid>
      </AlgoliaSearch>
    </Transition>
  )
}

export default SearchPage
